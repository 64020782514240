import React, { useState, useEffect } from 'react';

const CombinedCalculator = () => {
  const [activeTab, setActiveTab] = useState('dc');

  // DC Conversion Calculator states
  const [plotPrice, setPlotPrice] = useState(0);
  const [dimension, setDimension] = useState(0);
  const [srValue, setSRValue] = useState(0);
  const [loanAmount, setLoanAmount] = useState(0);
  const [downPayment, setDownPayment] = useState(0);
  const [cornerPlotPrice, setCornerPlotPrice] = useState(0);
  const [registrationFee, setRegistrationFee] = useState(0);
  const [emi5Years, setEMI5Years] = useState(0);
  const [emi10Years, setEMI10Years] = useState(0);
  const [emi15Years, setEMI15Years] = useState(0);
  const [facing, setFacing] = useState('one-facing');

  // BDA Calculator states
  const [bdaPlotPrice, setBdaPlotPrice] = useState("");
  const [bdaDimension, setBdaDimension] = useState("");
  const [loanPercent, setLoanPercent] = useState("80");
  const [bdaResult, setBdaResult] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // DC Conversion Calculator calculations
  const calculateDCValues = () => {
    const totalPrice = plotPrice * dimension;
    const loanAmt = (srValue * dimension * 0.8).toFixed(2);
    const registration = ((srValue * dimension * 0.066) + 40000).toFixed(2);
    const downPaymentAmount = (totalPrice - loanAmt).toFixed(2);
    
    let cornerPlotAmt = 0;
    if (facing === 'two-facing') {
      cornerPlotAmt = (srValue * dimension * 0.1).toFixed(2);
    }

    const interestRate = 0.09 / 12;
    const emi5 = (loanAmt * interestRate * Math.pow(1 + interestRate, 60)) / (Math.pow(1 + interestRate, 60) - 1);
    const emi10 = (loanAmt * interestRate * Math.pow(1 + interestRate, 120)) / (Math.pow(1 + interestRate, 120) - 1);
    const emi15 = (loanAmt * interestRate * Math.pow(1 + interestRate, 180)) / (Math.pow(1 + interestRate, 180) - 1);

    setLoanAmount(loanAmt);
    setRegistrationFee(registration);
    setDownPayment(downPaymentAmount);
    setCornerPlotPrice(cornerPlotAmt);
    setEMI5Years(emi5.toFixed(2));
    setEMI10Years(emi10.toFixed(2));
    setEMI15Years(emi15.toFixed(2));
  };

  // BDA Calculator calculations
  const calculateBDAValues = () => {
    const parsedPlotPrice = parseFloat(bdaPlotPrice) || 0;
    const parsedDimension = parseFloat(bdaDimension) || 0;
    const parsedLoanPercent = parseFloat(loanPercent) || 0;

    const totalAmount = parsedPlotPrice * parsedDimension + 25 * parsedDimension;
    const registrationAmount = 2880 * parsedDimension * 0.0665 + 30000;
    const loanAmount = 2880 * parsedDimension * (parsedLoanPercent / 100);
    const bdaDownPayment = totalAmount - loanAmount;
    const twentyPercentDP = totalAmount * 0.2;

    setBdaResult({
      totalAmount: totalAmount.toFixed(2),
      registrationAmount: registrationAmount.toFixed(2),
      loanAmount: loanAmount.toFixed(2),
      downPayment: bdaDownPayment.toFixed(2),
      twentyPercentDP: twentyPercentDP.toFixed(2),
    });
  };

  return (
    <div className="calculator-container" style={styles.container}>
      <div style={styles.tabContainer}>
        <button 
          style={{
            ...styles.tabButton,
            backgroundColor: activeTab === 'dc' ? '#0f0f7a' : '#fff',
            color: activeTab === 'dc' ? '#fff' : '#333',
          }}
          onClick={() => setActiveTab('dc')}
        >
          DC Conversion Calculator
        </button>
        <button 
          style={{
            ...styles.tabButton,
            backgroundColor: activeTab === 'bda' ? '#0f0f7a' : '#fff',
            color: activeTab === 'bda' ? '#fff' : '#333',
          }}
          onClick={() => setActiveTab('bda')}
        >
          BDA Calculator
        </button>
      </div>

      {activeTab === 'dc' ? (
        // DC Conversion Calculator
        <div>
          <h2 style={styles.header}>DC Conversion Calculator</h2>
          <div style={styles.inputGroup}>
            <label style={styles.label}>
              Plot Facing:
              <select 
                value={facing} 
                onChange={(e) => setFacing(e.target.value)}
                style={styles.input}
              >
                <option value="one-facing">One-Facing</option>
                <option value="two-facing">Two-Facing</option>
              </select>
            </label>
            <label style={styles.label}>
              Plot Price per Unit:
              <input
                type="number"
                value={plotPrice}
                onChange={(e) => setPlotPrice(parseFloat(e.target.value) || 0)}
                min="0"
                style={styles.input}
              />
            </label>
            <label style={styles.label}>
              Dimension (in square feet):
              <input
                type="number"
                value={dimension}
                onChange={(e) => setDimension(parseFloat(e.target.value) || 0)}
                min="0"
                style={styles.input}
              />
            </label>
            <label style={styles.label}>
              SR Value:
              <input
                type="number"
                value={srValue}
                onChange={(e) => setSRValue(parseFloat(e.target.value) || 0)}
                min="0"
                style={styles.input}
              />
            </label>
            <button onClick={calculateDCValues} style={styles.button}>Calculate</button>
          </div>
          
          {loanAmount > 0 && (
            <div style={styles.result}>
              <h3 style={styles.resultHeader}>Results:</h3>
              <div style={styles.resultItem}>
                <strong>Total Plot Price:</strong> ₹ {(plotPrice * dimension).toFixed(2)}
              </div>
              <div style={styles.resultItem}>
                <strong>Loan Amount:</strong> ₹ {loanAmount}
              </div>
              <div style={styles.resultItem}>
                <strong>Down Payment:</strong> ₹ {downPayment}
              </div>
              {facing === 'two-facing' && (
                <div style={styles.resultItem}>
                  <strong>Corner Plot Price (10% on SR value):</strong> ₹ {cornerPlotPrice}
                </div>
              )}
              <div style={styles.resultItem}>
                <strong>Registration Fee:</strong> ₹ {registrationFee}
              </div>
              <div style={styles.resultItem}>
                <strong>EMI for 5 Years:</strong> ₹ {emi5Years} per month
              </div>
              <div style={styles.resultItem}>
                <strong>EMI for 10 Years:</strong> ₹ {emi10Years} per month
              </div>
              <div style={styles.resultItem}>
                <strong>EMI for 15 Years:</strong> ₹ {emi15Years} per month
              </div>
            </div>
          )}
        </div>
      ) : (
        // BDA Calculator
        <div>
          <h2 style={styles.header}>BDA Calculator</h2>
          <div style={styles.inputGroup}>
            <label style={styles.label}>
              Plot Price (per sq ft):
              <input
                type="text"
                value={bdaPlotPrice}
                onChange={(e) => setBdaPlotPrice(e.target.value)}
                style={styles.input}
                placeholder="Enter plot price"
              />
            </label>
            <label style={styles.label}>
              Dimension (in sq ft):
              <input
                type="text"
                value={bdaDimension}
                onChange={(e) => setBdaDimension(e.target.value)}
                style={styles.input}
                placeholder="Enter dimension"
              />
            </label>
            <label style={styles.label}>
              Loan Percentage (%):
              <input
                type="text"
                value={loanPercent}
                onChange={(e) => setLoanPercent(e.target.value)}
                style={styles.input}
                placeholder="Enter loan percentage"
              />
            </label>
            <button style={styles.button} onClick={calculateBDAValues}>Calculate</button>
          </div>
          
          {bdaResult.totalAmount && (
            <div style={styles.result}>
              <h3 style={styles.resultHeader}>Results:</h3>
              <div style={styles.resultItem}>
                <strong>Total Amount:</strong> ₹{bdaResult.totalAmount}
              </div>
              <div style={styles.resultItem}>
                <strong>Registration Amount:</strong> ₹{bdaResult.registrationAmount}
              </div>
              <div style={styles.resultItem}>
                <strong>Loan Amount:</strong> ₹{bdaResult.loanAmount}
              </div>
              <div style={styles.resultItem}>
                <strong>Down Payment (Remaining):</strong> ₹{bdaResult.downPayment}
              </div>
              <div style={styles.resultItem}>
                <strong>20% Down Payment:</strong> ₹{bdaResult.twentyPercentDP}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    maxWidth: "800px",
    margin: "40px auto",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    border: "1px solid #eaeaea",
    marginTop:"112px"
  },
  tabContainer: {
    display: "flex",
    gap: "10px",
    marginBottom: "20px",
  },
  tabButton: {
    flex: 1,
    padding: "12px",
    fontSize: "16px",
    border: "none",
    borderRadius: "6px",
    cursor: "pointer",
    transition: "all 0.3s ease",
    border: "1px solid black"
  },
  header: {
    textAlign: "center",
    color: "#333",
    marginBottom: "20px",
    fontSize: "24px",
    fontWeight: "bold",
  },
  inputGroup: {
    margin: "15px 0",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  label: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#444",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "6px",
    border: "1px solid #ccc",
    outline: "none",
    transition: "all 0.2s ease",
    boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
  },
  button: {
    marginTop: "10px",
    padding: "12px",
    fontSize: "16px",
    backgroundColor: "#0f0f7a",
    color: "#fff",
    border: "none",
    borderRadius: "6px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  result: {
    marginTop: "30px",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    border: "1px solid #eaeaea",
  },
  resultHeader: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "15px",
  },
  resultItem: {
    fontSize: "16px",
    marginBottom: "12px",
    color: "#444",
  },
};

export default CombinedCalculator;